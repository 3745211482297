<script>
export default {};
</script>

<template>
  <div class="loader">
    <div class="loader__spinner"></div>
    <div class="loader__text">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: center;

  &__text {
    margin-left: 0.5rem;
  }

  &__spinner {
    display: inline-block;
    height: 32px;
    position: relative;
    width: 32px;

    &::before,
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #f9792f;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;

      -webkit-animation: bounce 2s infinite ease-in-out;
      animation: bounce 2s infinite ease-in-out;
    }

    &::after {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }
  }
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
</style>
