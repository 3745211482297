<script>
import { ref, onMounted } from 'vue';
import { appConfig } from './config';
import Loader from './components/Loader.vue';
import logo from './assets/icons/logo.svg';

export default {
  components: {
    Loader,
  },
  setup() {
    const options = { ...appConfig };
    const data = ref(null);
    const loading = ref(false);
    const error = ref({ status: false, message: '' });

    const fetchData = url => {
      return fetch(url)
        .then(async res => {
          const resData = await res.json();

          if (res.status >= 400 && res.status < 600) {
            if (resData?.message) {
              console.error(resData); // show full object if available
              throw new Error(resData.message);
            }

            throw new Error('Network error: could not fetch request...');
          }

          return resData;
        })
        .catch(err => {
          console.error(err.message);
          throw new Error(err.message);
        });
    };

    const getData = async () => {
      try {
        return await fetchData(options.api.url);
      } catch (err) {
        error.value = {
          status: true,
          message: err.message,
        };

        return null;
      }
    };

    onMounted(async () => {
      loading.value = true;
      data.value = (await getData()) || [];
      loading.value = false;
    });

    return {
      loading,
      error,
      data,
      logo,
    };
  },
};
</script>

<template>
  <section class="app">
    <header class="app__header header">
      <div class="header__container">
        <a class="header__logo" href="#">
          <img :src="logo" alt="Empowa logo" />
        </a>
        <nav class="nav">
          <ul class="nav__list">
            <li class="nav__item"><a class="nav__link" href="https://empowa.io">Home</a></li>
          </ul>
        </nav>
      </div>
    </header>
    <main class="app__main">
      <section class="section section__hero hero">
        <div class="hero__content">
          <h1>Project Catalyst</h1>
          <h2 style="position: relative; display: inline-block">NFT</h2>
        </div>
      </section>
      <section class="section">
        <div class="section__container">
          <div class="section__header">
            <h2 class="section__heading">Founding Community NFTs</h2>
            <h3 class="section__subheading">Catalyst Wallet Selections</h3>
          </div>
          <p>
            Thank you to our Founding Community NFT holders for helping select the Project Catalyst Fund5 voter wallets
            that will receive special Catalyst versions of our FC NFTs. The following numbers have already been
            submitted, which represent positions within the randomised wallet list of all Fund5 voters.
          </p>
        </div>
      </section>
      <section class="section section--table">
        <div class="section__container">
          <div class="section__table">
            <Loader v-if="loading">Loading selection...</Loader>
            <table class="table" v-if="data && data.length > 0">
              <thead class="table__header">
                <tr>
                  <td class="table__column">Submitted by</td>
                  <td class="table__column" style="text-align: right">Number submitted</td>
                  <td class="table__column">Transaction</td>
                </tr>
              </thead>
              <tbody class="table__body">
                <tr class="table__row" v-for="item in data" :key="item.fcnft">
                  <td class="table__column" style="min-width: 200px">{{ item.fcnft }}</td>
                  <td class="table__column" style="text-align: right">
                    {{ parseFloat(item.number_selected.substring(1)).toLocaleString('us-Us') }}
                  </td>
                  <td class="table__column">
                    <a :href="`https://cardanoscan.io/transaction/${item.tx_hash}`" target="_blank">{{
                      `${item.tx_hash.substring(0, 40)}...`
                    }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-else-if="data && data.length === 0" style="color: #f9792f">No numbers submitted yet!</p>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="section__container">
          <p>
            If you would like to participate, you will need to use a wallet that has a Founding Community NFT (Community
            or Premium) in it. Instructions on how to submit a number to be used in the selection process are available
            at
            <a
              style="word-break: break-word"
              href="https://www.reddit.com/r/Empowa/comments/qdabyt/catalyst_nfts_selection_process/"
              target="_blank"
              >https://www.reddit.com/r/Empowa/comments/qdabyt/catalyst_nfts_selection_process/</a
            >. You will only be able to submit one number, even if you hold multiple FC NFTs in the same wallet.
            Submissions for numbers already received will also be excluded, so please review the list of numbers above,
            before submitting your selection.
          </p>
        </div>
      </section>
    </main>
    <footer class="app__footer"></footer>
  </section>
</template>

<style lang="scss">
@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.app {
  &__loader {
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
  }
}

.header {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 2;
  color: #fff;

  &__container {
    @include container;
  }

  &__logo {
    display: block;
    margin: 0 auto 1rem auto;
    max-width: 200px;
  }
}

.nav {
  text-align: center;

  &__list {
    @include list-reset;
  }

  &__item {
    display: inline-block;
    margin-right: 2.5rem;

    &:last-child {
      margin: 0;
    }
  }

  &__link {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
  }
}

.hero {
  height: 300px;
  position: relative;
  background: url('assets/images/hero-bg.jpg') center / cover no-repeat;
  display: flex;

  &__content {
    color: #fff;
    margin: auto;
    text-align: center;
    padding-top: 6rem;
  }

  &__icon {
    max-width: 140px;
    margin: 0 auto 2rem auto;
  }
}

.section {
  &__container {
    @include container;
  }

  &__header {
    margin-bottom: 1rem;
  }

  &__heading {
    font-size: 24px;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  &__subheading {
    font-size: 16px;
    color: $color-primary;
    font-weight: 400;
  }

  &__table {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    ms-overflow-style: -ms-autohiding-scrollbar;
  }

  &--table {
    .section__container {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.table {
  border-spacing: 0;
  min-width: 400px;
  width: 100%;

  &__header {
    .table__column {
      border-bottom: solid 2px $color-primary;
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__body {
    .table__row {
      &:nth-child(odd) {
        background-color: #fef4e7;
      }
    }
  }

  &__footer {
    .table__column {
      font-weight: 600;
      border-top: solid 2px $color-primary;
    }
  }

  &__row {
    padding: 1rem;
    border-radius: 5px;
  }

  &__column {
    padding: 0.5rem 1rem;
  }
}

@media screen and (min-width: 768px) {
  .hero {
    height: 300px;

    &__content {
      padding-top: 4rem;
    }

    &__icon {
      max-width: 240px;
    }
  }

  .header {
    &__container {
      display: flex;
      align-items: center;
    }

    &__logo {
      display: inline-block;
      margin: 0 2rem 0 0;
    }
  }
}
</style>
