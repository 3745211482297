export const appConfig = {
  api: {
    url: 'https://data.empowa.io/submissions/',
    mockUrl: '/data.json',
  },
  fcNftLevels: {
    noFcNft: {
      label: 'No FC NFT',
      multiplier: 1,
    },
    community: {
      label: 'Community',
      multiplier: 1.5,
    },
    projectCatalyst: {
      label: 'Project Catalyst',
      multiplier: 1.5,
    },
    premium: {
      label: 'Premium',
      multiplier: 2,
    },
  },
  ispoPools: {
    emp1: {
      pool_id: 'pool16fa03u6u85ek2q8lryqprngpahglssj5qqh66pfaxf34vgu5rhl',
      pool_ticker: 'EMP1',
    },
    emp2: {
      pool_id: 'pool1f3qeehc4htjqpwv248kf5lfaumd0e84jtze379ev3ywa2w69aam',
      pool_ticker: 'EMP2',
    },
  },
};
